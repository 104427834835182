if (typeof window.CVO === 'undefined') {
  window.CVO = {};
}
window.CVO.submitForm = function (path, params, method = 'post') {

  // Make a hidden form and submit it
  const form = document.createElement('form')
  form.method = method
  form.action = path

  for (const key in params) {
    if (params.hasOwnProperty(key)) {
      const hiddenField = document.createElement('input')
      hiddenField.type = 'hidden'
      hiddenField.name = key
      hiddenField.value = params[key]

      form.appendChild(hiddenField)
    }
  }

  document.body.appendChild(form)
  form.submit()
}
